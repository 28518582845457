.root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main {
    flex: 1 1 auto;
    position: relative;
    padding: 0 var(--vertical-grid-size);
}
