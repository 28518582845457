@import-normalize; /* bring in normalize.css styles */
@import './color.css';
@import './typography.css';
@import './layout.css';

* {
    box-sizing: border-box;
}

a {
    color: currentColor;
    text-decoration: none;
}

p {
    margin-bottom: var(--vertical-grid-size);
}

img {
    max-width: 100%;
}

input {
    border: none;
    font-family: var(--text-font-family);
    padding: calc(var(--vertical-grid-size) / 2);
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    background-color: var(--color-body-bg);
    color: var(--color-text-primary);
    font-family: var(--text-font-family);
    font-weight: var(--text-font-weight);
    line-height: var(--line-height);
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--heading-font-family);
    font-weight: var(--heading-font-weight);
    font-size: var(--font-size-xxl);
    margin: 0;
}

button {
    appearance: none;
    border: none;
    background: none;
    color: var(--text-color-primary);
}
