.root {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    background-color: var(--color-header-bg);
    padding: calc(var(--vertical-grid-size) / 4);
    margin-bottom: var(--header-margin-bottom);
    position: sticky;
    top: 0;
    z-index: 1;
}

.nav {
    display: flex;
    gap: .5rem;
}

.active {
    text-decoration: underline;
}
