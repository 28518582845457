.preview {
    height: clamp(100px, 300px, 20vh);
    object-fit: contain;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

