.root {
    max-width: var(--max-line-length);
    margin-bottom: calc(var(--vertical-grid-size) * 2);
}

.comment {
    margin-bottom: var(--vertical-grid-size);
}

.content {
    white-space: pre-line;
}
